.popup-content {
	margin: auto;
	background: rgb(255, 255, 255);
	width: 50%;
	padding: 5px;

	.modal {
		display: inline-block;
		position: static;
		top: auto;
		left: auto;
	}
}
.popup-arrow {
	color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
	width: 200px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
.popup-overlay {
	background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
	background: transparent;
}

.popup-content {
	border-radius: 5px;
	padding: 0.5rem;
}
  
html[data-theme='dark'] .popup-content {
	background-color: rgb(41, 45, 62);
	color: #fff;
	border: 1px solid #9a9595;
}
  
html[data-theme='light'] .popup-content {
	background-color: #fff;
	color: #000;
	border: 1px solid #d7d7d7;
}
  
html[data-theme='dark'] .popup-arrow > svg {
	color: rgb(41, 45, 62);
	stroke-width: 2px;
	stroke: #9a9595;
	stroke-dasharray: 30px;
	stroke-dashoffset: -54px;
}

html[data-theme='light'] .popup-arrow > svg {
	color: #fff;
	stroke-width: 2px;
	stroke: #d7d7d7;
	stroke-dasharray: 30px;
	stroke-dashoffset: -54px;
}


.gp-lightbox__wrapper-overlay .popup-content {
	height: auto;
    min-width: 35vw;
    min-height: 400px;
	padding: 0;
	border-radius: 0;
	background-color: transparent;

	@media( max-width: 998px ) {
		min-width: 75vw;
	}

	.lightbox-inner__wrap,
	.lightbox-inner__contents {
		height: 100%;
		width: 100%;
		display: inline-block;
	}

	.lightbox-inner__contents {

		.content {
			max-height: 350px;
			overflow: hidden;
			overflow-y: scroll;
		}
	}

	.modal {
		position: relative;
		padding: 20px;
		border-radius: 6px;
		background-color: #fff;

		button.close {
			width: 35px;
			height: 35px;

			font-weight: 100;
			font-size: 44px;
			line-height: 0;

			box-shadow: none;
			text-shadow: none;

			display: flex;
			justify-content: center;
			align-items: center;

			position: absolute;
			top: 10px;
			right: 10px;

			&:hover,
			&:focus,
			&:active {
				background-color: transparent;
				opacity: 1;
			}
		}
	}

	.title {
		margin-bottom: 25px;
	}
	.feat-image {
		height: 300px;
		width: calc( 100% + 40px );
		margin: 0;
		overflow: hidden;
		
		position: relative;
		top: -20px;
		left: -20px;

		img {
			width: 100%;
			height: auto;
			position: absolute;
			top: -100px;
		}
	}
}