// Original
.App-v1 .carousel {
	position: relative;
	width: 360px;
	height: 360px;
	border: 2px solid;
	border-radius: 50%;
	transition: 0.5s;
	// transform: rotate(15deg);
	transform-origin: center center;
    &:before {
        /* content: ""; */
        position: absolute;
        width: 50%;
        height: 100%;
        background: #000;
        border-radius: 50% 0 0 50%;
    }

    .item-carousel {
        position: absolute;
        border-radius: 50%;
        border: 1px solid red;
        background: #fff;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin: 2px;
        // bottom: 0;
        transition: 0.5s;

        &.next {
            background: blue;
            color: white;
        }
        &.prev {
            background: green;
            color: white;
        }

        &.active {
            background: #000;
            color: white;
        }

        // &:nth-child(1) {
        //     top: -25px;
        //     right: calc(50% - 25px );
        // }
        // &:nth-child(2) {
        //     top: calc( 50px * 2);
        //     right: -25px;
        // }
        // &:nth-child(3) {
        //     top: calc( 50px * 5.5 );
        //     right: 25px;
        // }
        // &:nth-child(4) {
        //     top: calc( 50px * 5.5 );
        //     left: 25px;
        // }
        // &:nth-child(5) {
        //     top: calc( 50px * 2);
        //     left: -25px;
        // }
    }

    .item-carousel {
        &:nth-child(1) {
            right: -19px;
            top: 101px;
        }
        &:nth-child(2) {
            right: -17px;
            top: 213px;
        }
        &:nth-child(3) {
            right: 49px;
            top: 303px;
        }
        &:nth-child(4) {
            right: 156px;
            top: 337px;
        }
        &:nth-child(5) {
            right: 263px;
            top: 302px;
        }
        &:nth-child(6) {
            right: 330px;
            top: 210px;
        }
        &:nth-child(7) {
            right: 330px;
            top: 100px;
        }
        &:nth-child(8) {
            right: 263px;
            top: 7px;
        }
        &:nth-child(9) {
            right: 156px;
            top: -27px;
        }
        &:nth-child(10) {
            right: 48px;
            top: 6px;
        }
    }
}
// .test {
//   position: absolute;
//   left: 600px;
//   width: 20px;
//   height: 364px;
//   background: #000;

//   &:before {
//     content: '';
//     position: absolute;
//     width: 20px;
//     height: 20px;
//     background: red;
//     z-index: 1111;
//     top: 50%;
//     transform: translateY(-50%);
//   }
// }
