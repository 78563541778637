.material-shadow-1 {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.material-shadow-2 {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.material-shadow-3 {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.material-shadow-4 {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.material-shadow-5 {
	box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.material-shadow-hover-flat {
    @extend .cubic-bezier-035s;
	&:hover {
		@extend .material-shadow-3;
	}
}

.material-shadow-hover-1 {
	@extend .material-shadow-1;
    @extend .cubic-bezier-035s;
	&:hover {
		@extend .material-shadow-3;
	}
}
.material-shadow-hover-2 {
	@extend .material-shadow-2;
    @extend .cubic-bezier-035s;
	&:hover {
		@extend .material-shadow-4;
	}
}
.material-shadow-hover-3 {
	@extend .material-shadow-3;
    @extend .cubic-bezier-035s;
	&:hover {
		@extend .material-shadow-5;
	}
}

.material-card-flat {
	@extend .light-bg-color;
	@extend .material-shadow-hover-flat;
	border-radius: 6px;
	padding: 15px;
    margin: 0 0 25px;
}
.material-card-1 {
	@extend .light-bg-color;
	@extend .material-shadow-hover-1;
	border-radius: 6px;
	padding: 15px;
    margin: 0 0 25px;
}

.material-card-2 {
	@extend .light-bg-color;
	@extend .material-shadow-hover-2;
	border-radius: 6px;
	padding: 15px;
    margin: 0 0 25px;
}

.material-card-3 {
	@extend .light-bg-color;
	@extend .material-shadow-hover-3;
	border-radius: 6px;
	padding: 15px;
    margin: 0 0 25px;
}
.material-card-static-1 {
	@extend .light-bg-color;
	@extend .material-shadow-1;
	padding: 15px;
	margin: 0 0 25px;
	border-radius: 6px;
}
.material-card-static-2 {
	@extend .light-bg-color;
	@extend .material-shadow-2;
	padding: 15px;
	margin: 0 0 25px;
	border-radius: 6px;
}
.material-card-static-3 {
	@extend .light-bg-color;
	@extend .material-shadow-3;
	padding: 15px;
	margin: 0 0 25px;
	border-radius: 6px;
}

