body.lightbox-active {
    overflow: hidden;
}

.gp-lightbox {
	@extend .ease-in-out-05s;

	background-color: rgba( $light, .95 );
	height: 100%;
	width: 100%;
    max-height: 100vh;
	text-align: center;
	
	padding: 100px 0 0;

	opacity: 0;
	visibility: hidden;
	
	position: fixed;
	top: 0;
	left: 0;
	z-index: 600;

	display: flex;
	align-items: center;
	justify-content: center;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.gp-lightbox-inner {
		height: 100%; 
		width: 100%; 
		max-height: 100%;
		padding: 0 0 50px;
		
		display: flex; 
		align-items: center; 
		justify-content: center; 
		flex-wrap: wrap; 

		overflow-x: hidden;
		overflow-y: scroll;
	}
}

