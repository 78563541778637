
#primary {
    > .entry-header {
        @extend .material-card-static-1;
        h1 {
            margin: 0;
        }
    }
}
.post-item {
    @extend .material-card-1;

    .post-entry-header {
        width: calc( 100% + 30px );
        margin: -15px -15px 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        overflow: hidden;
        position: relative;

        &:after {
            content: '';
            background-color: rgba($dark, .65);
            width: 100%;
            height: 100%;
            display: inline-block;

            position: absolute;
            top: 0;
            left: 0;
        }
        .entry-title,
        .entry-meta {
            a {
                color: inherit;
            }
        }
        .entry-title {
            @extend .light-color;

            width: 100%;
            font-size: 26px;

            padding: 0 15px;
            margin: 0;

            display: inline-block;
            position: absolute;

            bottom: 15px;
            z-index: 1;
        }
        
        img.attachment-post-thumbnail {
            width: 100%;
            height: 300px;
            display: inline-block;
            object-fit: cover;
        }

        .entry-meta {
            @extend .light-color;

            width: 100%;
            font-size: 16px;

            padding: 0 15px;
            display: inline-block;

            position: absolute;
            top: 15px;
            left: 0;
            z-index: 1;
        }
        .post-edit-link {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 15;
        }
    }

}
.single {
    #comments {
        @extend .material-card-static-1;
        .form-submit {
            .submit {
                @extend .gp-btn-ripple;
            }
        }
    }
}