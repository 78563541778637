@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Raleway and Open Sans
$font_primary: 'Poppins', sans-serif;
$font_secondary: 'Open Sans', sans-serif;
$font_tertiary: 'Raleway', sans-serif;

// stylelint-disable value-keyword-case
$font__main: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
// stylelint-enable value-keyword-case
$font__code: monaco, consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
$font__pre: 'Courier 10 Pitch', courier, monospace;
$font__line-height-body: 1.75rem;
$font__line-height-pre: 1.6;
