#secondary {

    a {
        color: inherit;
        opacity: 0.5;
        &:focus,
        &:hover {
            opacity: 1;
        }
    }
    .widget {
        @extend .material-card-static-1;
        ul {
            margin: 0 0 25px;
            padding-left: 25px;
        }
    }
}
