.site-footer {
    @extend .secondary-bg-color;

    #copyright {
        @extend .light-color;
        padding: 20px 0;
        text-align: center;
    }
}
#site-footer-widgets {
    @extend .light-color;
    @extend .primary-bg-color;

    padding: 100px 0;

    a {
        @extend .link-inherit-parent-props;
    }
    .widget {
        ul {
            margin: 0 0 25px;
            padding-left: 25px;
        }
    }
}