#site-banner {
    // min-height: 100vh;
    position: relative;
    overflow: hidden;

    .inner-section {
        > .row {
            min-height: 800px;
        }
        .caption-col {
            .caption-wrap {
                .caption {
                }
            }
        }
        .carousel-col {

        }
    }
    
    .circle-carousel-wrapper.banner-carousel {
        width: auto;
        height: auto;
        display: inline-block;

        position: absolute;
        top: -50%;
        right: -25%;

        .carousel-wrap {
            @extend .secondary-bg-color;
        }

        .active-content {
            @extend .light-color;
            @extend .primary-bg-color;

            top: calc(100% - 140px);

            a {
                @extend .light-color;
            }
            .content-inner {
                .title {
                    font-size: 12px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 2.5px;
                }
            }
        }

        .carousel-controls {
            position: absolute;
            bottom: -40px;
            width: 100%;
            padding: 0 200px;

            display: flex;
            justify-content: space-between;

            .ctrl-btn {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                font-size: 0;
                &:before,
                &:after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                }
                &:before {
                    width: 0;
                    height: 30px;
                    border: 1px solid;
                    top: 14px;
                    left: 28.5px;
                }
                &:after {
                    width: 20px;
                    height: 20px;
                    border: 2px solid;
                    border-top: none;
                    border-left: none;
                    transform: rotate(45deg);
                    top: 20.5px;
                    left: 20px;
                }
            }
        }

        .carousel {
            border: none;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='500' ry='500' stroke='%230C5440FF' stroke-width='5' stroke-dasharray='70%2c 50' stroke-dashoffset='5' stroke-linecap='butt'/%3e%3c/svg%3e");

            position: absolute;
            top: 70%;
        }

        .item-carousel {
            border: 5px solid;
            @extend .primary-border-color;

            .item-inner .item-link {
                padding: 10px;
            }
        }
    }

    .caption-col {
        z-index: 10;
        align-self: center;

        .sub-caption {
            @extend .primary-color;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .caption {
            @extend .secondary-color;
            width: 45%;
        }
    }

	@media( max-width: 1200px ) {
        .inner-section {
            > .row {
                min-height: 600px;
            }
        }
		.circle-carousel-wrapper.banner-carousel {
			top: -85%;
		}
	}
    @media( max-width: 1100px ) {
		.circle-carousel-wrapper.banner-carousel {
            width: 100%;
            top: -75%;
            right: 0;

            .carousel-controls {
                padding: 0;
            }
		}
        .carousel {
            top: 70%;
        }

        .caption-col {
            .sub-caption {
            }
            .caption {
                width: 55%;
            }
        }
    }
    @media( max-width: 1090px ) {
        .circle-carousel-wrapper.banner-carousel {
            top: -55%;
            .carousel {
                top: 63%;
            }
        }
    }
    @media( max-width: 992px ) {
        .circle-carousel-wrapper.banner-carousel {
            .carousel {
                top: 75%;
            }
            .carousel-controls {
                padding: 0 20%;
            }
        }
        .inner-section {
            > .row {
                min-height: 750px;
                flex-direction: column;
                position: relative;
            }
            .caption-col {
                position: absolute;
                width: 100%;
                top: 110px;
                .caption-wrap {
                    text-align: center;
                    .caption {
                        width: 100%;
                        margin: 0 0 25px;
                    }
                }
            }
        }
        .circle-carousel-wrapper.banner-carousel {
            top: -65%;
        }
        .caption-col {
            align-self: start;
            .sub-caption {
                color: $light;
            }
            .caption {
                color: $primary;
            }
        }
    }
    @media( max-width: 990px ) {
        .circle-carousel-wrapper.banner-carousel {
            .carousel {
            }
            .carousel-controls {
                padding: 0 12.5%;
            }
        }
    }
    @media( max-width: 800px ) {
        .inner-section {
            > .row {
                min-height: 780px;
            }
        }
        .circle-carousel-wrapper.banner-carousel {
            top: -52%;
        }
    }
    @media( max-width: 785px ) {
        .circle-carousel-wrapper.banner-carousel {
            top: -40%;
            .carousel {
                top: 70%;
            }
        }
    }
    @media( max-width: 780px ) {
        .circle-carousel-wrapper.banner-carousel {
            top: -45%;
        }
        .inner-section {
            > .row {
                min-height: 650px;
            }
        }
    }
    @media( max-width: 770px ) {
        padding-bottom: 150px;
    }
    @media( max-width: 768px ) {
        .inner-section {
            .caption-col {
                .caption-wrap {
                    .caption {
                        font-size: 24px;
                    }
                }
            }
        }
        .circle-carousel-wrapper.banner-carousel {
            top: -48%;
            .active-content {
                top: calc(100% - 130px);
            }
        }
        .carousel-controls {
            bottom: -10%;
        }
    }
    @media( max-width: 765px ) {
        .circle-carousel-wrapper.banner-carousel {
            .carousel-controls {
                padding: 0;
            }
        }
    }
    @media( max-width: 725px ) {
        .circle-carousel-wrapper.banner-carousel {
            top: -45%;
        }
    }
    @media( max-width: 715px ) {
        .circle-carousel-wrapper.banner-carousel {
            top: -35%;
            .carousel-controls {
                padding: 0 10%;
            }
        }
    }
    @media( max-width: 710px ) {
        .carousel {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='200' ry='200' stroke='%230C5440FF' stroke-width='5' stroke-dasharray='30%2c 50' stroke-dashoffset='5' stroke-linecap='butt'/%3e%3c/svg%3e");
        }
    }
    @media( max-width: 600px ) {
        .carousel {
            top: 73%;
        }
    }
    @media( max-width: 585px ) {
        .circle-carousel-wrapper.banner-carousel {
            .carousel-controls {
                padding: 0 7.5%;
            }
        }
    }
    @media( max-width: 515px ) {
        .circle-carousel-wrapper.banner-carousel {
            .carousel-controls {
                padding: 0 5%;
            }
        }
    }
    @media( max-width: 490px ) {
        .circle-carousel-wrapper.banner-carousel {
            .active-content {
                top: calc(100% - 80px);
            }
        }
    }
    @media( max-width: 485px ) {
        .circle-carousel-wrapper.banner-carousel {
            .carousel-controls {
                padding: 0;
            }
        }
        .inner-section {
            .caption-col {
                .caption-wrap {
                    .caption {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    @media( max-width: 480px ) {
        .carousel {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='500' ry='500' stroke='%230C5440FF' stroke-width='5' stroke-dasharray='10%2c 5' stroke-dashoffset='5' stroke-linecap='butt'/%3e%3c/svg%3e");
        }
        .carousel-controls {
            padding: 0;
        }
    }
    @media( max-width: 455px ) {
        padding-bottom: 0;

        .inner-section > .row {
            min-height: 750px;
        }
        .circle-carousel-wrapper.banner-carousel {
            .carousel {
                top: 75%;
            }
        }
    }
    @media( max-width: 385px ) {
        .inner-section {
            .caption-col {
                .caption-wrap {
                    .caption {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    @media( max-width: 375px ) {
        .inner-section > .row {
            min-height: 650px;
        }
        .circle-carousel-wrapper.banner-carousel {
            top: -15%;
            .carousel {
                top: 70%;
            }
        }
    }
    @media( max-width: 350px ) {
        .inner-section {
            .caption-col {
                top: 140px;
                .caption-wrap {
                    .caption {
                        font-size: 16px;
                    }
                }
            }
        }
        .circle-carousel-wrapper.banner-carousel {
            top: -10%;
            .carousel {
                top: 75%;
            }
            .active-content {
                top: calc(100% - 80px);
            }
        }
        .carousel {
            top: 75%;
        }
        .carousel-controls {
            bottom: -10%;
        }
    }
}