.transition-none {
   -webkit-transition: all 0s;
      -moz-transition: all 0s;
        -o-transition: all 0s;
           transition: all 0s;
}
.ease-in-out-005s {
   -webkit-transition: all 0.05s ease-in-out;
      -moz-transition: all 0.05s ease-in-out;
        -o-transition: all 0.05s ease-in-out;
           transition: all 0.05s ease-in-out;
}
.ease-in-out-015s {
    -webkit-transition: all 0.15s ease-in-out;
       -moz-transition: all 0.15s ease-in-out;
         -o-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;
}
.ease-in-out-025s {
    -webkit-transition: all 0.25s ease-in-out;
       -moz-transition: all 0.25s ease-in-out;
         -o-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
}
.ease-in-out-035s {
   -webkit-transition: all 0.35s ease-in-out;
      -moz-transition: all 0.35s ease-in-out;
        -o-transition: all 0.35s ease-in-out;
           transition: all 0.35s ease-in-out;
}
.ease-in-out-045s {
   -webkit-transition: all 0.45s ease-in-out;
      -moz-transition: all 0.45s ease-in-out;
        -o-transition: all 0.45s ease-in-out;
           transition: all 0.45s ease-in-out;
}
.ease-in-out-05s {
   -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
           transition: all 0.5s ease-in-out;
}
.ease-in-out-1s {
   -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
           transition: all 1s ease-in-out;
}
.ease-in-out-1s {
   -webkit-transition: all 2s ease-in-out;
      -moz-transition: all 2s ease-in-out;
        -o-transition: all 2s ease-in-out;
           transition: all 2s ease-in-out;
}
.ease-in-out-3s {
   -webkit-transition: all 3s ease-in-out;
      -moz-transition: all 3s ease-in-out;
        -o-transition: all 3s ease-in-out;
           transition: all 3s ease-in-out;
}

.ease-in-out-0 {
   -webkit-transition: all 0s ease-in-out;
      -moz-transition: all 0s ease-in-out;
        -o-transition: all 0s ease-in-out;
           transition: all 0s ease-in-out;
}

.cubic-bezier-01s {
   -webkit-transition: all 0.1s cubic-bezier(.25,.8,.25,1);
      -moz-transition: all 0.1s cubic-bezier(.25,.8,.25,1);
         -o-transition: all 0.1s cubic-bezier(.25,.8,.25,1);
            transition: all 0.1s cubic-bezier(.25,.8,.25,1);
}
.cubic-bezier-015s {
   -webkit-transition: all 0.15s cubic-bezier(.25,.8,.25,1);
      -moz-transition: all 0.15s cubic-bezier(.25,.8,.25,1);
         -o-transition: all 0.15s cubic-bezier(.25,.8,.25,1);
            transition: all 0.15s cubic-bezier(.25,.8,.25,1);
}
.cubic-bezier-02s {
   -webkit-transition: all 0.2s cubic-bezier(.25,.8,.25,1);
      -moz-transition: all 0.2s cubic-bezier(.25,.8,.25,1);
         -o-transition: all 0.2s cubic-bezier(.25,.8,.25,1);
            transition: all 0.2s cubic-bezier(.25,.8,.25,1);
}
.cubic-bezier-025s {
   -webkit-transition: all 0.25s cubic-bezier(.25,.8,.25,1);
      -moz-transition: all 0.25s cubic-bezier(.25,.8,.25,1);
         -o-transition: all 0.25s cubic-bezier(.25,.8,.25,1);
            transition: all 0.25s cubic-bezier(.25,.8,.25,1);
}
.cubic-bezier-03s {
   -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      -moz-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
         -o-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.cubic-bezier-035s {
   -webkit-transition: all 0.35s cubic-bezier(.25,.8,.25,1);
      -moz-transition: all 0.35s cubic-bezier(.25,.8,.25,1);
         -o-transition: all 0.35s cubic-bezier(.25,.8,.25,1);
            transition: all 0.35s cubic-bezier(.25,.8,.25,1);
}
.cubic-bezier-04s {
   -webkit-transition: all 0.4s cubic-bezier(.25,.8,.25,1);
      -moz-transition: all 0.4s cubic-bezier(.25,.8,.25,1);
         -o-transition: all 0.4s cubic-bezier(.25,.8,.25,1);
            transition: all 0.4s cubic-bezier(.25,.8,.25,1);
}
.cubic-bezier-045s {
   -webkit-transition: all 0.45s cubic-bezier(.25,.8,.25,1);
      -moz-transition: all 0.45s cubic-bezier(.25,.8,.25,1);
         -o-transition: all 0.45s cubic-bezier(.25,.8,.25,1);
            transition: all 0.45s cubic-bezier(.25,.8,.25,1);
}
.cubic-bezier-05s {
   -webkit-transition: all 0.5s cubic-bezier(.25,.8,.25,1);
      -moz-transition: all 0.5s cubic-bezier(.25,.8,.25,1);
         -o-transition: all 0.5s cubic-bezier(.25,.8,.25,1);
            transition: all 0.5s cubic-bezier(.25,.8,.25,1);
}

.transform-origin-top {
   -webkit-transform-origin: top;
      -moz-transform-origin: top;
           transform-origin: top;
}
.transform-origin-left {
   -webkit-transform-origin: left;
      -moz-transform-origin: left;
           transform-origin: left;
}
.transform-origin-center {
   -webkit-transform-origin: center;
      -moz-transform-origin: center;
           transform-origin: center;
}
.transform-origin-right {
   -webkit-transform-origin: right;
      -moz-transform-origin: right;
           transform-origin: right;
}.transform-origin-bottom {
   -webkit-transform-origin: bottom;
      -moz-transform-origin: bottom;
           transform-origin: bottom;
}