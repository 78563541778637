html {
	scroll-behavior: smooth;
}
.menux-nav__active,
.menux-nav__mobile-menu__activated.menux-nav__active {
	.menux-nav__component-wrapper {
		visibility: visible;
		opacity: 1;
	}
}
.menux-nav__mobile-menu__activated {
	.menux-nav__component-wrapper {
		width: 100%;
		height: 100%;
		background: #fff;
		
		display: inline-block;
		justify-content: unset;
		align-items: unset;

		position: fixed;
		top: 0;
		left: 0;

		visibility: hidden;
		opacity: 0;
		-webkit-transition: all 0.5s ease-in-out;
		   -moz-transition: all 0.5s ease-in-out;
			-ms-transition: all 0.5s ease-in-out;
		  	 -o-transition: all 0.5s ease-in-out;
				transition: all 0.5s ease-in-out;

		.nav-menu {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			
			.menu-item {
				padding: 0;
				a {
					margin: 20px 0;
					font-size: 24px;
					text-transform: uppercase;
				}
			}
		}
	}
}

.menux-nav__component-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

	// Align Items Horizontal
	&.justify-content__left {
		justify-content: flex-start;
	}
	&.justify-content__center {
		justify-content: center;
	}
	&.justify-content__right {
		justify-content: flex-end;
	}

	// Align Items Vertical
	&.align-items__top {
		align-items: flex-start;
	}
	&.align-items__center {
		align-items: center;
	}
	&.align-items__bottom {
		align-items: flex-end;
	}

	.nav-menu {
		padding: 0;
		margin: 0;
		list-style: none;

		width: 100%;
		display: flex;
		align-items: inherit;
		justify-content: inherit;
	
		.menu-item {
			padding: 5px;
	
			display: flex;
			justify-content: center;
			align-items: center;
	
			> * {
				display: flex;
			}
	
			.main-menu-toggle {
				&[data-animate="menu-bars"] {
					width: 20px;
				}
				&[data-state="on"] {
					.bars {
						top: 2.5px;
					}
				}
				&[data-state="off"] {
					.bars {
						top: 0;
					}
				}
			}
			.search-box-toggle {
				&[data-state="on"] {
					.bars {
						top: 0;
					}
				}
				&[data-state="off"] {
					.bars {
						top: 2.5px;
					}
				}
			}
			.header-widgets-toggle {
				font-size: 20px;
				.state-on,
				.state-off {
					position: relative;
				}
				.state-off {
					top: 2.5px;
				}
				.state-on {
					top: -2.5px;
				}
			}
		}
	
		.menu-item {
			&:first-child .item-link {
				margin-left: 0;
			}
			&:last-child .item-link {
				margin-right: 0;
			}
			.item-link.active,
			&:active .item-link,
			&:hover .item-link,
			&:focus .item-link {
				@extend .primary-color;
				@extend .light-bg-color;
			}
		}
	
		.item-link {
			@extend .primary-bg-color;
			@extend .light-color;
			padding: 5px 15px;
			margin: 0 15px;
			border-radius: 20px;
			font-size: 12px;
		}
	}
}

.menux-nav-trigger {

	&.active {
		span {
			background-color: #f57167;
		}		
	}

	span {
		background-color: #fff;
		height: 5px;
		width: 100%;
	}

	&.style-1, 
	&.style-2, 
	&.style-3, 
	&.style-4 {
		width: 35px;
		height: 35px;
		position: relative;
		margin: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		-o-transition: .5s ease-in-out;
		transition: .5s ease-in-out;
		cursor: pointer;
	}

	&.style-1 span, 
	&.style-3 span, 
	&.style-4 span {
		display: block;
		position: absolute;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
	}

	// Style 1
	&.style-1 {
		span:nth-child(1) {
			top: 4px;
		}
		
		span:nth-child(2) {
		  top: 16px;
		}
		span:nth-child(3) {
		  top: 28px;
		}
		&.active {
			span:nth-child(1) {
				top: 16px;
				-webkit-transform: rotate(135deg);
				-moz-transform: rotate(135deg);
				-o-transform: rotate(135deg);
				transform: rotate(135deg);
			}
			span:nth-child(2) {
				opacity: 0;
				left: -30px;
			}
			span:nth-child(3) {
			  top: 16px;
			  -webkit-transform: rotate(-135deg);
			  -moz-transform: rotate(-135deg);
			  -o-transform: rotate(-135deg);
			  transform: rotate(-135deg);
			}
		}
	}

	// Style 2
	&.style-2 {
		span {
			width: 70%;
			display: block;
			position: absolute;
			opacity: 1;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;

			&:nth-child(even) {
				left: 50%;
				border-radius: 0 9px 9px 0;
			}
			&:nth-child(odd) {
				left:0px;
				border-radius: 9px 0 0 9px;
			}
			&:nth-child(1), 
			&:nth-child(2) {
				top: 4px;
			}
			&:nth-child(3), 
			&:nth-child(4) {
				top: 16px;
			}
			&:nth-child(5), 
			&:nth-child(6) {
				top: 28px;
			}
		}
		&.active {
			span:nth-child(1),
			span:nth-child(6) {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			span:nth-child(2),
			span:nth-child(5) {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}

			span:nth-child(1) {
				top: 6.5px;
				left: 0;
			}
			span:nth-child(2) {
				top: 6.5px;
				left: calc(50% - 1px);
			}
			span:nth-child(3) {
				opacity: 0;
				left: -50%;
			}
			span:nth-child(4) {
				opacity: 0;
				left: 100%;
			}
			span:nth-child(5) {
				top: 20px;
				left: 0;
			}
			span:nth-child(6) {
				top: 20px;
				left: calc(50% - 1px);
			}
		}
	}
	// Style 3
	&.style-3 {
		span {
			&:nth-child(1) {
				top: 4px;
			}
			&:nth-child(2),
			&:nth-child(3) {
				top: 16px;
			}
			&:nth-child(4) {
				top: 28px;
			}
		}
		&.active {
			span {
				&:nth-child(1) {
					width: 0%;
					top: 18px;
					left: 50%;
				}
				&:nth-child(2) {
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
				}
				&:nth-child(3) {
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
	
				&:nth-child(4) {
					width: 0%;
					top: 18px;
					left: 50%;
				}
			}
		}
	}
	
	// Style 4
	&.style-4 {
		span {
			&:nth-child(1) {
				top: 4px;
				-webkit-transform-origin: left center;
				-moz-transform-origin: left center;
				-o-transform-origin: left center;
				transform-origin: left center;
			}
			&:nth-child(2) {
				top: 16px;
				-webkit-transform-origin: left center;
				-moz-transform-origin: left center;
				-o-transform-origin: left center;
				transform-origin: left center;
			}
			&:nth-child(3) {
				top: 28px;
				-webkit-transform-origin: left center;
				-moz-transform-origin: left center;
				-o-transform-origin: left center;
				transform-origin: left center;
			}
		}
		&.active {
			span {
				&:nth-child(1) {
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
					top: 5.5px;
					left: 8px;
				}
				  
				&:nth-child(2) {
					width: 0%;
					opacity: 0;
				}
				  
				&:nth-child(3) {
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
					top: 28.5px;
					left: 8px;
				}
			}
		}
	}
}

.menux-nav-lightbox__wrapper-overlay {
	button.close {
		color: currentColor;
	}
}