.homepage {
	.section {
		&#testimonials-section {
			background: $color__background-body;
			.section-title {
				height: 0;
				position: relative;
				top: -60px;
			}
			
			.gp-carousel {
				.slick-slide {
					padding: 40px 20px;
				}
				.slick-prev,
				.slick-next {
					&:before {
						font-size: 36px;
					}
				}
				.slick-prev {
					left: 0;
				}
				.slick-next {
					right: 0;
				}
			}
		}

		&#portfolios-section {
			.gridz-component-wrapper {
				.inner-wrap {
					.content-row {
						.item {
							&:hover {
								&:after {
									opacity: 1;
									visibility: visible;
								}
							}
							&:after {
								@extend .gp-fontawesome-after;
								@extend .ease-in-out-025s;
								@extend .light-color;

								content: "\f002";

								width: 100%;
								height: 100%;

								font-size: 40px;
							
								display: flex;
								align-items: center;
								justify-content: center;

								cursor: pointer;
							
								opacity: 0;
								visibility: hidden;
								
								position: absolute;
								top: 0;
								left: 0;
							}
							.title {
								font-size: 0;
							}
						}
					}
				}
			}
		}
	}
}