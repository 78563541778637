input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: $color__text-input;
	border: 1px solid $color__border-input;
	border-radius: 3px;
	padding: 3px;

	@extend .gp-placeholder-dark;
	&:focus {
		color: $color__text-input-focus;
	}
}

select {
	border: 1px solid $color__border-input;
}

textarea {
	width: 100%;
}

.gp-placeholder-dark {
	&::-webkit-input-placeholder {
		color: $dark_00;
		-webkit-transition: color 0.25s ease-in-out;
		-moz-transition: color 0.25s ease-in-out;
		-o-transition: color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out;
	}

	&:-moz-placeholder {
		/* Firefox 18- */
		color: $dark_00;
		-webkit-transition: color 0.25s ease-in-out;
		-moz-transition: color 0.25s ease-in-out;
		-o-transition: color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out;
	}

	&::-moz-placeholder {
		/* Firefox 19+ */
		color: $dark_00;
		-webkit-transition: color 0.25s ease-in-out;
		-moz-transition: color 0.25s ease-in-out;
		-o-transition: color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out;
	}

	&:-ms-input-placeholder {
		color: $dark_00;
		-webkit-transition: color 0.25s ease-in-out;
		-moz-transition: color 0.25s ease-in-out;
		-o-transition: color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out;
	}
	&:hover {
		&::-webkit-input-placeholder {
			color: $dark;
		}

		&:-moz-placeholder {
			/* Firefox 18- */
			color: $dark;
		}

		&::-moz-placeholder {
			/* Firefox 19+ */
			color: $dark;
		}

		&:-ms-input-placeholder {
			color: $dark;
		}
	}
}
.gp-placeholder-light {
	&::-webkit-input-placeholder {
		color: $light_00;
		-webkit-transition: color 0.25s ease-in-out;
		-moz-transition: color 0.25s ease-in-out;
		-o-transition: color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out;
	}

	&:-moz-placeholder {
		/* Firefox 18- */
		color: $light_00;
		-webkit-transition: color 0.25s ease-in-out;
		-moz-transition: color 0.25s ease-in-out;
		-o-transition: color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out;
	}

	&::-moz-placeholder {
		/* Firefox 19+ */
		color: $light_00;
		-webkit-transition: color 0.25s ease-in-out;
		-moz-transition: color 0.25s ease-in-out;
		-o-transition: color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out;
	}

	&:-ms-input-placeholder {
		color: $light_00;
		-webkit-transition: color 0.25s ease-in-out;
		-moz-transition: color 0.25s ease-in-out;
		-o-transition: color 0.25s ease-in-out;
		transition: color 0.25s ease-in-out;
	}
	&:hover {
		&::-webkit-input-placeholder {
			color: $light;
		}

		&:-moz-placeholder {
			/* Firefox 18- */
			color: $light;
		}

		&::-moz-placeholder {
			/* Firefox 19+ */
			color: $light;
		}

		&:-ms-input-placeholder {
			color: $light;
		}
	}
}
.gp-placeholder-light-plain {
	&::-webkit-input-placeholder {
		color: $light;
	}

	&:-moz-placeholder {
		/* Firefox 18- */
		color: $light;
	}

	&::-moz-placeholder {
		/* Firefox 19+ */
		color: $light;
	}

	&:-ms-input-placeholder {
		color: $light;
	}
}

.gp-render-field-style {
	@extend .light-color;
	// @extend .grey-03-border-color;
	@extend .light-border-color;
	@extend .ease-in-out-015s;

	background-color: transparent;
	border-width: 0;
	border-style: solid;
	border-bottom-width: 1px;
	border-radius: 0;
	&:active,
	&:hover,
	&:focus {
		@extend .grey-01-bg-color;
		outline: none;
	}
}
