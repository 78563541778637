/* Header
--------------------------------------------- */
@import "header/header";

/* Navigations
--------------------------------------------- */
@import "navigations/navigations";

/* Banners
--------------------------------------------- */
@import "banner/banner";

/* Sections
--------------------------------------------- */
@import "sections/sections";

/* Posts
--------------------------------------------- */
@import "posts/posts";

/* Footer
--------------------------------------------- */
@import "footer/footer";

/* Sidebars
--------------------------------------------- */
@import "sidebars/site-sidebar"; 
// @import "sidebars/content-sidebar"; // Uncomment this line for a sidebar on right side of your content.
// @import "sidebars/sidebar-content"; // Uncomment this line for a sidebar on left side of your content.