.site-wrapper {
    &.sticky-on {

    }
    &.sticky-off {
    }
}

.section {
    padding-bottom: 80px;
    
    // Padding
    &.section-padding__bottom__none {
        padding-bottom: 0;
    }
    &.section-padding__bottom__small {
        padding-bottom: 40px;
    }
    &.section-padding__bottom__medium {
        padding-bottom: 140px;
    }
    &.section-padding__bottom__large {
        padding-bottom: 200px;
    }
    &.section-padding__bottom__xlarge {
        padding-bottom: 260px;
    }
    
    // Margin
    &.section-margin__bottom__none {
        margin-bottom: 0;
    }
    &.section-margin__bottom__small {
        margin-bottom: 40px;
    }
    &.section-margin__bottom__medium {
        margin-bottom: 140px;
    }
    &.section-margin__bottom__large {
        margin-bottom: 200px;
    }
    &.section-margin__bottom__xlarge {
        margin-bottom: 260px;
    }
    .section-title {
        @extend .primary-color;
        text-align: center;
        margin: 0 0 60px;
    }
}