.transition-delay-01s {
    -webkit-transition-delay: 0.1s;
       -moz-transition-delay: 0.1s;
            transition-delay: 0.1s;
}
.transition-delay-015s {
    -webkit-transition-delay: 0.15s;
       -moz-transition-delay: 0.15s;
            transition-delay: 0.15s;
}
.transition-delay-02s {
    -webkit-transition-delay: 0.2s;
       -moz-transition-delay: 0.2s;
            transition-delay: 0.2s;
}
.transition-delay-025s {
    -webkit-transition-delay: 0.25s;
       -moz-transition-delay: 0.25s;
            transition-delay: 0.25s;
}
.transition-delay-03s {
    -webkit-transition-delay: 0.3s;
       -moz-transition-delay: 0.3s;
            transition-delay: 0.3s;
}
.transition-delay-035s {
    -webkit-transition-delay: 0.35s;
       -moz-transition-delay: 0.35s;
            transition-delay: 0.35s;
}
.transition-delay-04s {
    -webkit-transition-delay: 0.4s;
       -moz-transition-delay: 0.4s;
            transition-delay: 0.4s;
}
.transition-delay-045s {
    -webkit-transition-delay: 0.45s;
       -moz-transition-delay: 0.45s;
            transition-delay: 0.45s;
}
.transition-delay-05s {
    -webkit-transition-delay: 0.5s;
       -moz-transition-delay: 0.5s;
            transition-delay: 0.5s;
}
.transition-delay-055s {
    -webkit-transition-delay: 0.55s;
       -moz-transition-delay: 0.55s;
            transition-delay: 0.55s;
}
.transition-delay-06s {
    -webkit-transition-delay: 0.6s;
       -moz-transition-delay: 0.6s;
            transition-delay: 0.6s;
}
.transition-delay-065s {
    -webkit-transition-delay: 0.65s;
       -moz-transition-delay: 0.65s;
            transition-delay: 0.65s;
}
.transition-delay-07s {
    -webkit-transition-delay: 0.7s;
       -moz-transition-delay: 0.7s;
            transition-delay: 0.7s;
}
.transition-delay-075s {
    -webkit-transition-delay: 0.75s;
       -moz-transition-delay: 0.75s;
            transition-delay: 0.75s;
}
.transition-delay-08s {
    -webkit-transition-delay: 0.8s;
       -moz-transition-delay: 0.8s;
            transition-delay: 0.8s;
}
.transition-delay-085s {
    -webkit-transition-delay: 0.85s;
       -moz-transition-delay: 0.85s;
            transition-delay: 0.85s;
}
.transition-delay-09s {
    -webkit-transition-delay: 0.9s;
       -moz-transition-delay: 0.9s;
            transition-delay: 0.9s;
}
.transition-delay-095s {
    -webkit-transition-delay: 0.95s;
       -moz-transition-delay: 0.95s;
            transition-delay: 0.95s;
}
.transition-delay-1s {
    -webkit-transition-delay: 1s;
       -moz-transition-delay: 1s;
            transition-delay: 1s;
}