.fade-out {
	animation: fadeOut ease 10s;
	-webkit-animation: fadeOut ease 10s;
	-moz-animation: fadeOut ease 10s;
	-o-animation: fadeOut ease 10s;
	-ms-animation: fadeOut ease 10s;
}
@keyframes fadeOut {
	0% {
		opacity:1;
	}
	100% {
		opacity:0;
		visibility: hidden;
	}
}
  
@-moz-keyframes fadeOut {
	0% {
		opacity:1;
	}
	100% {
		opacity:0;
		visibility: hidden;
	}
}
  
@-webkit-keyframes fadeOut {
	0% {
		opacity:1;
	}
	100% {
		opacity:0;
		visibility: hidden;
	}
}
  
@-o-keyframes fadeOut {
	0% {
		opacity:1;
	}
	100% {
		opacity:0;
		visibility: hidden;
	}
}
  
@-ms-keyframes fadeOut {
	0% {
		opacity:1;
	}
	100% {
		opacity:0;
		visibility: hidden;
	}
}