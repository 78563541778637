// Import variables and mixins.
@import 'abstracts/abstracts';

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import 'base/base';

/*--------------------------------------------------------------
# Custom Components
--------------------------------------------------------------*/
@import 'custom_components/includes';

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
@import 'layouts/layouts';

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import 'components/components';

/*--------------------------------------------------------------
# Pages
--------------------------------------------------------------*/
@import 'pages/pages';

/*--------------------------------------------------------------
# Overwrites
--------------------------------------------------------------*/
@import 'overwrite/overwrite';

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/

/**
 * Accessibility
 */
@import 'utilities/accessibility';

/**
 * Alignments
 */
@import 'utilities/alignments';

/**
 * Animations
 */
@import 'animations/animations';

/**
 * Responsive
 */
@import 'responsive/responsive';


.gp-lightbox__wrapper-overlay .popup-content .feat-image {
	height: auto;
}
.gp-lightbox__wrapper-overlay .popup-content .feat-image img {
	height: auto;
	max-height: 400px;
	width: 100%;
	object-fit: cover;
	object-position: top;
	position: relative;
	top: auto;
}

@media( max-width: 992px ) {
	.gp-lightbox__wrapper-overlay .popup-content .lightbox-inner__contents .content {
		max-height: 400px;
	}

	.form-main__wrapper .row.form-field__row:first-child > .col-12.col-md-6:first-child {
		margin-bottom: 15px;
	} 
	.gp-lightbox__wrapper-overlay .popup-content .feat-image {
		height: auto;
	}
	.gp-lightbox__wrapper-overlay .popup-content .feat-image img {
		height: auto;
		max-height: 400px;
		width: 100%;
		object-fit: cover;
		object-position: top;
		position: relative;
		top: auto;
	}
}
@media( max-width: 768px ) {
	.gp-lightbox__wrapper-overlay .popup-content {
		min-width: 96vw;
	}
}