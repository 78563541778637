.gp-header {
	@extend .transparent-bg;
	@extend .primary-color;
	min-height: 75px;
	display: inline-block;

	&.sticky-header {
		@extend .ease-in-out-025s;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;

		&.sticky {
			@extend .light-bg-color;
			@extend .material-shadow-2;
			top: 0;

			.menux-nav__component-wrapper .nav-menu {
				.menu-item {
					.item-link.active,
					&:active .item-link,
					&:hover .item-link,
					&:focus .item-link {
						@extend .light-color;
						@extend .secondary-bg-color;
					}
				}
			}
			.menux-nav-trigger span {
				@extend .primary-bg-color;
			}
		}
		
		&.sticky-hidden {
			top: -100%
		}
	}

	.masthead-inner {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.site-navigation-left {
		.nav-menu {
			// left: -20px;
		}
	}
	.site-navigation-right {
		.nav-menu {
			// right: -20px;
		}
	}
}

.menux-nav__active {
	.gp-header .menux-nav-trigger.active span {
		background-color: #f57167;
	}
}

.menux-nav__mobile-menu__activated {
	.gp-header.sticky-header.sticky .menux-nav__component-wrapper .nav-menu,
	.menux-nav__component-wrapper .nav-menu {
		.menu-item {
			.item-link {
				@extend .primary-color;
				@extend .transparent-bg;
			}

			.item-link.active,
			&:active .item-link,
			&:hover .item-link,
			&:focus .item-link {
				@extend .light-color;
				@extend .primary-bg-color;
			}
		}
	}

	.gp-header {
		@extend .transparent-bg;
		@extend .primary-color;
		min-height: 75px;
		display: inline-block;
	
		&.sticky-header {
			@extend .ease-in-out-025s;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;
	
			&.sticky {
				@extend .light-bg-color;
				@extend .material-shadow-2;
				top: 0;
			}
			
			&.sticky-hidden {
				top: -100%
			}
		}
	}
}