@use 'sass:math';

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
	$numberColumnsMinusOne: $numberColumns - 1;
	$columnsMarginTimes: $columnsMargin * $numberColumnsMinusOne;
	$columnsMarginDivide: math.div($columnsMarginTimes, $numberColumns);
	$mapGetColumns: map-get($columns, $numberColumns);

	// width: map-get($columns, $numberColumns) - (($columnsMargin * ($numberColumns - 1)) / $numberColumns);
	width: $mapGetColumns - $columnsMarginDivide;
}

@mixin set_flex_grid( $flexSize: 33.3333%, $gutterSize: 20px ) {
	
	$size: 0 1 100%;

	@if $gutterSize == none {
		$size: 0 1 $flexSize;
	} @else if $gutterSize == 0px {
		$size: 0 1 $flexSize;
	} @else if $flexSize == 100% {
		$size: 0 1 100%;
	} @else {
		$size: 0 1 calc($flexSize - $gutterSize);
	}

	-webkit-box-flex: 0;
	-ms-flex: $size;
	flex: $size;
}