.gp-pagination-container {
	.gp-pagination {
		ul,
		li {
			border: none;
		}

		ul {
            padding: 0;
            margin: 0 0 25px;

            display: flex;
            align-items: center;
            justify-content: center;

			li {
				margin: 0 5px;
				border: none;
                display: inline-block;

				@extend .material-shadow-hover-1;
				border-radius: 6px;
				overflow: hidden;

                .page-numbers {
                    @extend .primary-color;
					@extend .light-bg-color;
					@extend .primary-border-color;
					@extend .ripple-effect;

					min-width: 45px;
					padding: 13px 17px;
					font-size: 16px;
					font-weight: 600;
					text-align: center;
					border: none;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    &:focus,
                    &:hover,
                    &.current {
                        @extend .light-color;
                        @extend .primary-bg-color;
                        @extend .primary-border-color;
                    }
					&.prev,
					&.next {
						font-size: 0;
						padding: 13.2222px 12px;
						&:before {
							@extend .gp-fontawesome-before;
							font-size: 16px;
						}
					}
					&.prev {
						&:before {
							content: "\f104";
						}
					}
					&.next {
						&:before {
							content: "\f105";
						}
					}
                    
                    &.prev {}
                    &.next {}
                    &.dots {}
                }

                
				a:focus,
				a:hover,
				span.current {
					@extend .light-color;
					@extend .primary-bg-color;
					@extend .primary-border-color;
				}
				a,
				span {
					@extend .primary-color;
					@extend .light-bg-color;
					@extend .primary-border-color;
					@extend .ripple-effect;

					min-width: 45px;
					padding: 13px 17px;
					font-size: 16px;
					font-weight: 600;
					text-align: center;
					border: none;
				}
				a {
					
					&.prev,
					&.next {
						font-size: 0;
						padding: 13.2222px 12px;
						&:before {
							@extend .gp-fontawesome-before;
							font-size: 16px;
						}
					}
					&.prev {
						&:before {
							content: "\f104";
						}
					}
					&.next {
						&:before {
							content: "\f105";
						}
					}
				}
			}
		}
	}
}