.fade-in {
	animation: fadeIn ease 10s;
	-webkit-animation: fadeIn ease 10s;
	-moz-animation: fadeIn ease 10s;
	-o-animation: fadeIn ease 10s;
	-ms-animation: fadeIn ease 10s;
}
  
@keyframes fadeOut {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
  
@-moz-keyframes fadeOut {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
  
@-webkit-keyframes fadeOut {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
  
@-o-keyframes fadeOut {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
  
@-ms-keyframes fadeOut {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}