[data-ui="trigger"] {
	cursor: pointer;
}

[data-ui="animate-clicks"] {
	position: relative;
	display: inline-block;

	&[data-animate="menu-bars"],
	&[data-animate="custom-icon"] {
		&[data-state="on"] {
			.bars {
				.bar {
					&.top {
						@extend .transform-rotate-45deg;
						top: 4px;
					}
					&.mid {
						opacity: 0;
					}
					&.bottom {
						@extend .transform-rotate--45deg;
						top: -10px;
					}
				}
			}
		}

		.bars {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 5;
			cursor: pointer;
			.bar {
				background: #1a1a1a;
				display: block;
				height: 3px;
				margin: 4px 0;
				position: relative;
				// transition: all 0.5s;
				@extend .ease-in-out-025s;
				width: 100%;
			}
		}
	}
	&[data-animate="menu-bars"] {
		width: 30px;
		.bars {
			position: relative;
			display: inline-block;
		}
	}
	&[data-animate="custom-icon"] {
		min-width: 15px;
	    text-align: center;

		&[data-state="on"] {
			[data-ui="trigger"] {
				visibility: hidden;
			}
			.bars {
				opacity: 1;
				visibility: visible;
			}
		}
		.bars {
			opacity: 0;
			visibility: hidden;
		}
	}
}
