.loader_screen {
    width: 100vw;
    height: 100vh;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;

	&.loader_screen--hide {
		visibility: hidden;
		opacity: 0;
	}
}