.gp-primary-font {
	font-family: $font_primary;
}
.gp-secondary-font {
	font-family: $font_secondary;
}
.gp-tertiary-font {
	font-family: $font_tertiary;
}

// Title Font Size
.gp-xlarge-title {
	font-size: 7rem;
	line-height: 7.5rem;
}
.gp-large-title {
	font-size: 6rem;
	line-height: 6.5rem;
}
.gp-midium-title {
	font-size: 5rem;
	line-height: 5.5rem;
}
.gp-small-title {
	font-size: 4rem;
	line-height: 4.5rem;
}
.gp-xsmall-title {
	font-size: 3rem;
	line-height: 3.5rem;
}

// Sub Title Font Size
.gp-xlarge-subtitle {
	font-size: 6rem;
	line-height: 6.5rem;
}
.gp-large-subtitle {
	font-size: 5rem;
	line-height: 5.5rem;
}
.gp-midium-subtitle {
	font-size: 4rem;
	line-height: 4.5rem;
}
.gp-small-subtitle {
	font-size: 3rem;
	line-height: 3.5rem;
}
.gp-xsmall-subtitle {
	font-size: 2rem;
	line-height: 2.5rem;
}

// Text Font Size
.gp-xlarge-text {
	font-size: 2.25rem;
	line-height: 2.75rem;
}
.gp-large-text {
	font-size: 1.75rem;
	line-height: 2.25rem;
}
.gp-xmedium-text {
	font-size: 1.5rem;
	line-height: 2rem;
}
.gp-medium-text {
	font-size: 1.25rem;
	line-height: 1.75rem;
}
.gp-normal-text {
	font-size: 1rem;
	line-height: 1.5rem;
}
.gp-small-text {
	font-size: 0.75rem;
	line-height: 1.25rem;
}
.gp-body-typography {
	@extend .gp-primary-font;
	color: $color__text-main;
	// font-family: $font__main;
	font-size: 1rem;
	// line-height: 1.75rem;
	line-height: $font__line-height-body;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	@extend .gp-primary-font;
}

button,
input,
select,
optgroup,
textarea {
	@extend .gp-primary-font;
}

body {
	@extend .gp-body-typography;
}
body {
	font-size: 14px;
	font-weight: 400;
	line-height: 18.2px;
}

@import "headings";
@import "copy";
