@-webkit-keyframes gp-loader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes gp-loader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes close-bar-1 {
	0% {
		width: 0;
		transform: rotate(45deg);
	}
	20% {
		opacity: 1;
	}
	100% {
		width: 100%;
	}
}
@keyframes close-bar-2 {
	0% {
		width: 100%;
		opacity: 1;
	}
	100% {
		width: 0;
		opacity: 0;
	}
}
@keyframes close-bar-3 {
	0% {
		width: 0;
		transform: rotate(-45deg);
	}
	20% {
		opacity: 1;
	}
	100% {
		width: 100%;
		opacity: 1;
	}
}

/**
 * Material Ripple Effect
 */
@keyframes material-ripple {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(2.5);
	}
}