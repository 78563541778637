a {
	color: $color__link;
	text-decoration: none;
	@extend .ease-in-out-025s;
	
	&:visited {
		// color: $color__link-visited;
		text-decoration: none;
	}

	&:hover,
	&:focus,
	&:active {
		color: $color__link-hover;
		text-decoration: none;
	}

	&:focus {
		outline: thin dotted;
	}

	&:hover,
	&:active {
		outline: 0;
	}
}

.link-inherit-parent-props {
	color: inherit;
	opacity: 0.5;
	&:focus,
	&:hover {
		opacity: 1;
	}
}