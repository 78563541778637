.gp-grid {
	display: grid;
	grid-template-columns: calc(20% - 16px) calc(20% - 16px) calc(20% - 16px) calc(20% - 16px) calc(20% - 16px);
	grid-column-gap: 20px;
	grid-row-gap: 20px;

	// grid-auto-flow: column;

	&.column-1 {
		grid-template-columns: 100%;
		justify-content: center;
	}
	&.column-2 {
		grid-template-columns: calc(50% - 15px) calc(50% - 15px);
	}
	&.column-3 {
		grid-template-columns: calc(33.33% - 14px) calc(33.33% - 14px) calc(33.33% - 14px);
	}
	&.column-4 {
		grid-template-columns: calc(25% - 22px) calc(25% - 22px) calc(25% - 22px) calc(25% - 22px);
	}
	&.column-5 {
		grid-template-columns: calc(20% - 16px) calc(20% - 16px) calc(20% - 16px) calc(20% - 16px) calc(20% - 16px);
	}
}


.gp-column-grid {
	column-count: 3;
	column-gap: 10px;
	// column-rule-width: 1px;
	// column-rule-style: solid;
	// column-rule-color: lightblue;
	// column-span: all;
	&.column-1 {
		column-count: 1;
	}
	&.column-2 {
		column-count: 2;
	}
	&.column-3 {
		column-count: 3;
	}
	&.column-4 {
		column-count: 4;
	}
	&.column-5 {
		column-count: 5;
	}
	&.column-6 {
		column-count: 6;
	}
}