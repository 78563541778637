$primary: #0B5440; // #7f1933;
$primary_link: rgba($primary, 0.75); // rgba( 127, 25, 51, 0.75 );

$secondary: #B4D83C;
$secondary_link: rgba($secondary, 0.75);

$fallback: #edecec;
$fallback_01: #c5c5c5;

$light: #ffffff;
$light_00: rgba(255, 255, 255, 0.5);
$light_01: #f1f1f1;
$light_02: #c5c5c5;
$light_03: #e6e6e6;
$light_04: #e9ecef;
// $light_03: #d8d7d7;

$dark: #000000;
$dark_00: #111111;
$dark_01: #010101;
$dark_02: #020202;
$dark_03: #030303;
$dark_04: #040404;
$dark_05: #050505;
$dark_06: #060606;
$dark_07: #212529;

$border: #b2b2b2;

$blue: #03a9f4;

$red: #f44336;
$red_01: #e03735;

$green: #4caf50;
$green_01: #8bc34a;

$yellow: #ffeb3b;
$yellow_01: #ff9800;

$orange: #ff5722;

$amber: #ffc107;
$teal: #009688;
$cyan: #00bcd4;
$indigo: #3f51b5;
$deep_purple: #673ab7;
$pink: #e91e63;
$lime: #cddc39;
$brown: #795548;

$grey: #9e9e9e;
$grey_01: #262626;
$grey_02: #3b3b3b;
$grey_03: #505050;

$color__background-body: $fallback;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #21759b;
$color__text-input: $dark_02;
$color__text-input-focus: $dark_01;

$color__link: lighten($primary, 8%); // #4169e1 royalblue
$color__link-visited: $primary; // #800080 purple
$color__link-hover: $primary; // #191970 midnightblue

$color__text-main: $dark_07;

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;

$color__border-input: #ccc;
$color__border-abbr: #666;
