.transform-scale-0 {
	-webkit-transform: scale(0);
	-moz-animation: scale(0);
	transform: scale(0);
}
.transform-scale-05 {
	-webkit-transform: scale(0.5);
	-moz-animation: scale(0.5);
	transform: scale(0.5);
}
.transform-scale-1 {
	-webkit-transform: scale(1);
	-moz-animation: scale(1);
	transform: scale(1);
}
.transform-scale-1-2 {
	-webkit-transform: scale(1.2);
	-moz-animation: scale(1.2);
	transform: scale(1.2);
}
.transform-scale-1-3 {
	-webkit-transform: scale(1.3);
	-moz-animation: scale(1.3);
	transform: scale(1.3);
}
.transform-scale-1-4 {
	-webkit-transform: scale(1.4);
	-moz-animation: scale(1.4);
	transform: scale(1.4);
}
.transform-scale-1-5 {
	-webkit-transform: scale(1.5);
	-moz-animation: scale(1.5);
	transform: scale(1.5);
}
.transform-scale-1-6 {
	-webkit-transform: scale(1.6);
	-moz-animation: scale(1.6);
	transform: scale(1.6);
}
// Translate Y
.transform-translateY-0 {
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	transform: translateY(0);
}
.transform-translateY-25px {
	-webkit-transform: translateY(25px);
	-moz-transform: translateY(25px);
	transform: translateY(25px);
}
.transform-translateY-50px {
	-webkit-transform: translateY(50px);
	-moz-transform: translateY(50px);
	transform: translateY(50px);
}
.transform-translateY-75px {
	-webkit-transform: translateY(75px);
	-moz-transform: translateY(75px);
	transform: translateY(75px);
}
.transform-translateY-100px {
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	transform: translateY(100px);
}
.transform-translateY-125px {
	-webkit-transform: translateY(125px);
	-moz-transform: translateY(125px);
	transform: translateY(125px);
}
.transform-translateY-150px {
	-webkit-transform: translateY(150px);
	-moz-transform: translateY(150px);
	transform: translateY(150px);
}
.transform-translateY-175px {
	-webkit-transform: translateY(175px);
	-moz-transform: translateY(175px);
	transform: translateY(175px);
}
.transform-translateY-200px {
	-webkit-transform: translateY(200px);
	-moz-transform: translateY(200px);
	transform: translateY(200px);
}
.transform-translateY--25px {
	-webkit-transform: translateY(-25px);
	-moz-transform: translateY(-25px);
	transform: translateY(-25px);
}
.transform-translateY--50px {
	-webkit-transform: translateY(-50px);
	-moz-transform: translateY(-50px);
	transform: translateY(-50px);
}
.transform-translateY--100px {
	-webkit-transform: translateY(-100px);
	-moz-transform: translateY(-100px);
	transform: translateY(-100px);
}
.transform-translateY--150px {
	-webkit-transform: translateY(-150px);
	-moz-transform: translateY(-150px);
	transform: translateY(-150px);
}
.transform-translateY--200px {
	-webkit-transform: translateY(-200px);
	-moz-transform: translateY(-200px);
	transform: translateY(-200px);
}

.transform-translateY-25-percent {
	-webkit-transform: translateY(25%);
	-moz-transform: translateY(25%);
	transform: translateY(25%);
}
.transform-translateY-50-percent {
	-webkit-transform: translateY(50%);
	-moz-transform: translateY(50%);
	transform: translateY(50%);
}
.transform-translateY-100-percent {
	-webkit-transform: translateY(100%);
	-moz-transform: translateY(100%);
	transform: translateY(100%);
}
.transform-translateY-125-percent {
	-webkit-transform: translateY(125%);
	-moz-transform: translateY(125%);
	transform: translateY(125%);
}
.transform-translateY-150-percent {
	-webkit-transform: translateY(150%);
	-moz-transform: translateY(150%);
	transform: translateY(150%);
}
.transform-translateY-175-percent {
	-webkit-transform: translateY(175%);
	-moz-transform: translateY(175%);
	transform: translateY(175%);
}
.transform-translateY-200-percent {
	-webkit-transform: translateY(200%);
	-moz-transform: translateY(200%);
	transform: translateY(200%);
}
.transform-translateY--25-percent {
	-webkit-transform: translateY(-25%);
	-moz-transform: translateY(-25%);
	transform: translateY(-25%);
}
.transform-translateY--50-percent {
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
}
.transform-translateY--100-percent {
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	transform: translateY(-100%);
}
.transform-translateY--150-percent {
	-webkit-transform: translateY(-150%);
	-moz-transform: translateY(-150%);
	transform: translateY(-150%);
}
.transform-translateY--200-percent {
	-webkit-transform: translateY(-200%);
	-moz-transform: translateY(-200%);
	transform: translateY(-200%);
}
.transform-translateY--70vh {
	-webkit-transform: translateY(-70vh);
	-moz-transform: translateY(-70vh);
	transform: translateY(-70vh);
}

// Translate X
.transform-translateX-0 {
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	transform: translateX(0);
}
.transform-translateX-25px {
	-webkit-transform: translateX(25px);
	-moz-transform: translateX(25px);
	transform: translateX(25px);
}
.transform-translateX-50px {
	-webkit-transform: translateX(50px);
	-moz-transform: translateX(50px);
	transform: translateX(50px);
}
.transform-translateX-100px {
	-webkit-transform: translateX(100px);
	-moz-transform: translateX(100px);
	transform: translateX(100px);
}
.transform-translateX-125px {
	-webkit-transform: translateX(125px);
	-moz-transform: translateX(125px);
	transform: translateX(125px);
}
.transform-translateX-150px {
	-webkit-transform: translateX(150px);
	-moz-transform: translateX(150px);
	transform: translateX(150px);
}
.transform-translateX-175px {
	-webkit-transform: translateX(175px);
	-moz-transform: translateX(175px);
	transform: translateX(175px);
}
.transform-translateX-200px {
	-webkit-transform: translateX(200px);
	-moz-transform: translateX(200px);
	transform: translateX(200px);
}
.transform-translateX--25px {
	-webkit-transform: translateX(-25px);
	-moz-transform: translateX(-25px);
	transform: translateX(-25px);
}
.transform-translateX--50px {
	-webkit-transform: translateX(-50px);
	-moz-transform: translateX(-50px);
	transform: translateX(-50px);
}
.transform-translateX--100px {
	-webkit-transform: translateX(-100px);
	-moz-transform: translateX(-100px);
	transform: translateX(-100px);
}
.transform-translateX--150px {
	-webkit-transform: translateX(-150px);
	-moz-transform: translateX(-150px);
	transform: translateX(-150px);
}
.transform-translateX--200px {
	-webkit-transform: translateX(-200px);
	-moz-transform: translateX(-200px);
	transform: translateX(-200px);
}

.transform-translateX-25-percent {
	-webkit-transform: translateX(25%);
	-moz-transform: translateX(25%);
	transform: translateX(25%);
}
.transform-translateX-50-percent {
	-webkit-transform: translateX(50%);
	-moz-transform: translateX(50%);
	transform: translateX(50%);
}
.transform-translateX-100-percent {
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	transform: translateX(100%);
}
.transform-translateX-125-percent {
	-webkit-transform: translateX(125%);
	-moz-transform: translateX(125%);
	transform: translateX(125%);
}
.transform-translateX-150-percent {
	-webkit-transform: translateX(150%);
	-moz-transform: translateX(150%);
	transform: translateX(150%);
}
.transform-translateX-175-percent {
	-webkit-transform: translateX(175%);
	-moz-transform: translateX(175%);
	transform: translateX(175%);
}
.transform-translateX-200-percent {
	-webkit-transform: translateX(200%);
	-moz-transform: translateX(200%);
	transform: translateX(200%);
}
.transform-translateX--25-percent {
	-webkit-transform: translateX(-25%);
	-moz-transform: translateX(-25%);
	transform: translateX(-25%);
}
.transform-translateX--50-percent {
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	transform: translateX(-50%);
}
.transform-translateX--100-percent {
	-webkit-transform: translateX(-100%);
	-moz-transform: translateX(-100%);
	transform: translateX(-100%);
}
.transform-translateX--150-percent {
	-webkit-transform: translateX(-150%);
	-moz-transform: translateX(-150%);
	transform: translateX(-150%);
}
.transform-translateX--200-percent {
	-webkit-transform: translateX(-200%);
	-moz-transform: translateX(-200%);
	transform: translateX(-200%);
}
/**
 * Rotate
 */

// 45 Deg
.transform-rotate-45deg {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	transform: rotate(45deg);
}
.transform-rotate--45deg {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
// 90 Deg
.transform-rotate-90deg {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	transform: rotate(90deg);
}
.transform-rotate--90deg {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	transform: rotate(-90deg);
}
