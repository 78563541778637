body {
	@extend .primary-color;
	background: $color__background-body; // Fallback for when there is no custom background color defined.
	word-break: break-word;
	min-height: 100vh;
	overflow-x: hidden;
	background-image: url( '/images/backgropund.png' );
	background-size: 80px;

	#app {
		height: 100%;
	}
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	#main.content-area {
		flex: 1;
	}
}

.gp-main-container {
	&.sticky-header {

	}
	
	#wrapper.content-wrapper {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}