@media screen and (max-device-width: 375px) and (orientation: landscape) {
	/* Header
    --------------------------------------------- */
	@import 'header/header';

	/* Banner
    --------------------------------------------- */
	@import 'banner/banner';

	/* Sections
    --------------------------------------------- */
	@import 'sections/sections';

	/* Widgets
    --------------------------------------------- */
	@import 'widgets/widgets';

	/* Components
    --------------------------------------------- */
	@import 'components/components';

	/* Layouts
    --------------------------------------------- */
	@import 'layouts/layouts';

	/* Pages
    --------------------------------------------- */
	@import 'pages/pages';

	/* Sidebars
    --------------------------------------------- */
	@import 'sidebars/sidebars';

	/* Footer
    --------------------------------------------- */
	@import 'footer/footer';

	/* Plugins
    --------------------------------------------- */
	@import 'plugins/plugins';
}
