@import "effects/fade_in";
@import "effects/fade_out";

.gp-core-loading {
	font-size: 10px;
	text-indent: -9999em;
	margin: 0 auto;
	border: 2.5px solid currentColor; // rgba(0, 0, 0, 0.35);
	border-left: 2.5px solid transparent; // rgba(0, 0, 0, 0.68);
	opacity: 0.5;
	display: inline-block;
	position: relative;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: gp-loader 0.5s infinite linear;
	animation: gp-loader 0.5s infinite linear;
}

.gp-circle-animate {
	width: 30px;
	height: 20px;
	background-color: transparent;
	border: none;
	outline: none;

	// opacity: 0.6;
	cursor: pointer;
	z-index: 2;
	display: inline-block;

	position: relative;

	transition: opacity 0.2s ease-out;

	&.animate,
	&:hover {
		&:before {
			@extend .transform-scale-1;
		}
		&:after {
			@extend .transform-scale-1-2;
		}
		.bar {
			@extend .light-bg-color;
		}
	}

	&:before,
	&:after {
		@extend .primary-bg-color;
		@extend .ease-in-out-025s;
		@extend .transform-origin-center;
		@extend .transform-scale-0;
		content: '';
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: inline-block;
		position: absolute;
		top: -20.5px;
		left: -15px;
	}

	&:after {
		opacity: 0.5;
		z-index: -1;
		top: -20px;
	}
	* {
		position: relative;
		z-index: 1;
	}
	.bar {
		// @extend .primary-bg-color;
		@extend .primary-bg-color;
		height: 2.5px;
		width: 100%;
		margin: 0 auto;

		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;

		transition: opacity 0.2s ease-out;
		// transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out;

		&:nth-child(1),
		&:nth-child(3) {
			opacity: 1;
			width: 100%;
			top: 50%;
		}
		&:nth-child(1) {
			transform: rotate(45deg);
			animation: close-bar-1 0.15s 1 ease-out;
		}
		&:nth-child(2) {
			width: 0;
			animation: close-bar-2 0.15s 1 ease-out;
		}
		&:nth-child(3) {
			transform: rotate(-45deg);
			animation: close-bar-3 0.15s 1 ease-out;
		}
	}
}