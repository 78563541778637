.gp-carousel {
	.slick-slide {
		padding: 40px 50px;
	}
	.inner-slide-wrapper {
		@extend .light-bg-color;
		@extend .material-shadow-1;

		padding: 60px 20px 20px;
		border-radius: 12px;
		position: relative;
		
		&.positioned__center {
			text-align: center
		}
		&.positioned__left {
			text-align: left
		}
		&.positioned__right {
			text-align: right
		}
		.title {
			font-size: 18px;
			margin: 0 0 15px;
		}
		.sub-title {
			font-size: 14px;
			margin: 0 0 15px;
		}
		.image {
			@extend .light-bg-color;
			@extend .material-shadow-1;

			width: 80px;
			height: 80px;
			border-radius: 50%;
			overflow: hidden;
			
			position: absolute;
			top: -40px;
			left: calc(50% - 40px);
		}
	}

	// Navigation
	.slick-prev,
	.slick-next {
		box-shadow: none;
		cursor: pointer;
		&:before {
			@extend .gp-fontawesome-before;
			@extend .primary-color;
		}
	}
	.slick-prev {
		left: -10px;
		&:before {
			content: "\f053";
		}
	}
	.slick-next {
		right: -10px;
		&:before {
			content: "\f054";
		}
	}

	// Pagination
	.slick-dots {
		list-style: none;
		bottom: -30px;
		li {
			list-style: none;
			height: auto;
			width: auto;
			
			&.slick-active {
				button {
					&:before {
						@extend .primary-color;
					}
				}
			}

			button {
				height: 20px;
				width: 20px;
				min-height: unset;
				padding: 0;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				&:before {
					@extend .primary-color;
					font-size: 10px;
					top: 1px;
					left: 1px;
				}
			}
		}
	}
}