/* Primary Color
--------------------------------------------- */
.primary-color {
	color: $primary;
}
.primary-bg-color {
	background-color: $primary;
}
.primary-border-color {
	border-color: $primary;
}

/* Secondary Color
--------------------------------------------- */
.secondary-color {
	color: $secondary;
}
.secondary-bg-color {
	background-color: $secondary;
}
.secondary-border-color {
	border-color: $secondary;
}

/* fallback Color
--------------------------------------------- */
.fallback-color {
	color: $fallback;
}
.fallback-bg-color {
	background-color: $fallback;
}
.fallback-border-color {
	border-color: $fallback;
}

/* Light Color
--------------------------------------------- */
.light-color {
	color: $light;
}
.light-bg-color {
	background-color: $light;
}
.light-border-color {
	border-color: $light;
}

/* Light 01 Color
--------------------------------------------- */
.light-01-color {
	color: $light_01;
}
.light-01-bg-color {
	background-color: $light_01;
}
.light-01-border-color {
	border-color: $light_01;
}

/* Light 02 Color
--------------------------------------------- */
.light-02-color {
	color: $light_02;
}
.light-02-bg-color {
	background-color: $light_02;
}
.light-02-border-color {
	border-color: $light_02;
}

/* Light 03 Color
--------------------------------------------- */
.light-03-color {
	color: $light_03;
}
.light-03-bg-color {
	background-color: $light_03;
}
.light-03-border-color {
	border-color: $light_03;
}

/* Dark Color
--------------------------------------------- */
.dark-color {
	color: $dark;
}
.dark-bg-color {
	background-color: $dark;
}
.dark-border-color {
	border-color: $dark;
}

/* Dark 01 Color
--------------------------------------------- */
.dark-01-color {
	color: $dark_01;
}
.dark-01-bg-color {
	background-color: $dark_01;
}
.dark-01-border-color {
	border-color: $dark_01;
}

/* Dark 02 Color
--------------------------------------------- */
.dark-02-color {
	color: $dark_02;
}
.dark-02-bg-color {
	background-color: $dark_02;
}
.dark-02-border-color {
	border-color: $dark_02;
}

/* Dark 03 Color
--------------------------------------------- */
.dark-03-color {
	color: $dark_03;
}
.dark-03-bg-color {
	background-color: $dark_03;
}
.dark-03-border-color {
	border-color: $dark_03;
}

/* Dark 04 Color
--------------------------------------------- */
.dark-04-color {
	color: $dark_04;
}
.dark-04-bg-color {
	background-color: $dark_04;
}
.dark-04-border-color {
	border-color: $dark_04;
}
/* Dark 05 Color
--------------------------------------------- */
.dark-05-color {
	color: $dark_05;
}
.dark-05-bg-color {
	background-color: $dark_05;
}
.dark-05-border-color {
	border-color: $dark_05;
}
/* Dark 06 Color
--------------------------------------------- */
.dark-06-color {
	color: $dark_06;
}
.dark-06-bg-color {
	background-color: $dark_06;
}
.dark-06-border-color {
	border-color: $dark_06;
}
/* Dark 07 Color
--------------------------------------------- */
.dark-07-color {
	color: $dark_07;
}
.dark-07-bg-color {
	background-color: $dark_07;
}
.dark-07-border-color {
	border-color: $dark_07;
}

/* Blue Color
--------------------------------------------- */
.blue-color {
	color: $blue;
}
.blue-bg-color {
	background-color: $blue;
}
.blue-border-color {
	border-color: $blue;
}

/* Red Color
--------------------------------------------- */
.red-color {
	color: $red;
}
.red-bg-color {
	background-color: $red;
}
.red-border-color {
	border-color: $red;
}

/* Green Color
--------------------------------------------- */
.green-color {
	color: $green;
}
.green-bg-color {
	background-color: $green;
}
.green-border-color {
	border-color: $green;
}

/* Orange Color
--------------------------------------------- */
.orange-color {
	color: $orange;
}
.orange-bg-color {
	background-color: $orange;
}
.orange-border-color {
	border-color: $orange;
}

/* Yellow Color
--------------------------------------------- */
.yellow-color {
	color: $yellow;
}
.yellow-bg-color {
	background-color: $yellow;
}
.yellow-border-color {
	border-color: $yellow;
}

/* Grey Color
--------------------------------------------- */
.grey-color {
	color: $grey;
}
.grey-bg-color {
	background-color: $grey;
}
.grey-border-color {
	border-color: $grey;
}
/* Grey 01 Color
--------------------------------------------- */
.grey-01-color {
	color: $grey_01;
}
.grey-01-bg-color {
	background-color: $grey_01;
}
.grey-01-border-color {
	border-color: $grey_01;
}
/* Grey 02 Color
--------------------------------------------- */
.grey-02-color {
	color: $grey_02;
}
.grey-02-bg-color {
	background-color: $grey_02;
}
.grey-02-border-color {
	border-color: $grey_02;
}
/* Grey 03 Color
--------------------------------------------- */
.grey-03-color {
	color: $grey_03;
}
.grey-03-bg-color {
	background-color: $grey_03;
}
.grey-03-border-color {
	border-color: $grey_03;
}

.transparent-bg {
	background-color: transparent
}