
.circle-carousel-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .carousel-inner-wrap {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .carousel-wrap {
        display: inline-block;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        display: flex;
        // transition: 0.5s;
    }

    .carousel-controls {
        position: absolute;
        bottom: 0;
    }

    // Carousel Active Content
    .active-content {
        background-color: yellow;
        text-align: center;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;
        z-index: 10;
        position: absolute;

        .content-inner {
            width: 100%;
            height: 100%;
            padding: 25px;
            text-align: center;
            border-radius: 50%;

            &.has-bg-color {

            }
            &.has-bg-image {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            .item-link {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .title {
                width: 100%;
                display: inline-block;
            }

            .icon {
                width: 70%;
                height: auto;
                display: inline-block;
            }
        }
    }

    // Carousel Items
    .carousel {
        position: relative;
        // width: 360px;
        // height: 360px;
        border: 2px solid;
        border-radius: 50%;
        transition: 0.5s;
        // transform: rotate(15deg);
        transform-origin: center center;
        &:before {
            /* content: ""; */
            position: absolute;
            width: 50%;
            height: 100%;
            background: #000;
            border-radius: 50% 0 0 50%;
        }
    
    }

    .carousel-inner {
        height: 100%;
        width: 100%;
        position: relative;
        display: inline-block;
        overflow: visible;
    }

    .item-carousel {
        min-width: 50px;
        min-height: 50px;
        background: #fff;
        border-radius: 50%;
        
        font-size: 14px;
        margin: 2px;

        display: flex;
        align-items: center;
        justify-content: center;
        
        overflow: hidden;
        position: absolute;
        transition: all 0.5s;
        // transition: transform 0.5s;
        // transition: transform 0.5s, width 0.5s, height 0.5s, top 0.5s, left 0.5s;
        // transition: transform 0.5s, top 0.5s, left 0.5s;

        .item-inner {
            width: 100%;
            height: 100%;
            // padding: 25px;
            text-align: center;
            border-radius: 50%;
            
            &.has-bg-color {

            }
            &.has-bg-image {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            .item-link {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .title {
                width: 100%;
                display: inline-block;
            }

            .icon {
                width: 100%;
                height: auto;
                display: inline-block;
            }
        }
    }

    .item-carousel {
        &.next {
        }
        &.prev {
        }
        &.active {
        }
    }
}
.circle-carousel-wrapper {
    &.debug-on {
        &:before,
        &:after {
            content: "";
            width: 1px;
            background: green;
            height: 150%;
            position: absolute;
            z-index: 1000;
        }
        &:after {
            transform: rotate(90deg);
        }

        .item-carousel {
            &.next {
                background: blue;
                color: white;
            }
            &.prev {
                background: green;
                color: white;
            }
    
            &.active {
                background: #000;
                color: white;
            }
        }
    }
}