.gp-fontawesome-before,
.gp-fontawesome-after {
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}
/**
 * Name of the FA free font (mandatory), e.g.:
 * 
 * - 'Font Awesome 5 Free' for Regular and Solid symbols;
 * - 'Font Awesome 5 Pro' for Regular and Solid symbols (Professional License);
 * - 'Font Awesome 5 Brand' for Brands symbols.
 *
 * Weight of the font (mandatory):
 *
 * - 400 for Regular and Brands symbols;
 * - 900 for Solid symbols;
 * - 300 for Light symbols.
 */
.gp-fontawesome-before {
	&:before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		display: inline-block;
		font-style: normal;
	}
}
.gp-fontawesome-after {
	&:after {
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		display: inline-block;
		font-style: normal;
	}
}

.fa,
.fas,
.far,
.fab {
	font-style: normal;
}
