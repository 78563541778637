button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	// border: 1px solid;
	// border-color: $color__border-button;
	// border-radius: 3px;
	// background: $color__background-button;
	@extend .light-color;
	line-height: 1;
	padding: 0.6em 1em 0.4em;

	&:hover {
		// border-color: $color__border-button-hover;
	}

	&:active,
	&:focus {
		// border-color: $color__border-button-focus;
	}
}