.gp-lightbox {
    .navigation-menu {
        padding: 0;
        margin: 0;
        list-style: none;

        .menu-item {
            display: block;
            margin: 30px 0;
            a {
                @extend .light-02-color;
                display: block;
                font-size: 32px;
                font-weight: 800;
                letter-spacing: 5px;
                text-transform: uppercase;
                text-decoration: none;
            }

            &.current_page_item,
            a:hover {
                @extend .dark-07-color;
            }

            .sub-menu {
                padding: 0;
                margin: 0;
            }
        }
    }

    .searchform {
        @extend .light-02-color;
        width: 650px;

        .search-form-wrapper {
            
        }
        
        .search-field {
            width: 100%;
            
            border: none;
            outline: none;

            font-size: 50px;
            text-align: left;
            background-color: transparent;
        
            &:hover,
            &:focus,
            &:active {
                border: none;
                outline: none;
            }
        }
    }

    &.header-widgets-lightbox {
        #site-header-widgets {
        
            a {
                color: inherit;
                opacity: 0.5;
                &:focus,
                &:hover {
                    opacity: 1;
                }
            }
            .widget-collections {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: start;
                gap: 25px;

                .widget {
                    @include set_flex_grid( 25%, 20px );
                    @extend .material-card-static-1;
                    margin: 0;
                    text-align: left;
                }
            }
            .widget {
                ul {
                    margin: 0 0 25px;
                    padding-left: 25px;
                }
            }
        }
    }
}