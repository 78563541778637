.gridz-lightbox__active {
    overflow: hidden;
}


.gridz-component-wrapper {
	&.rows-alternate__reverse {
		.inner-wrap {
			.content-row {
				&:nth-child(odd) {
					direction: rtl;
					.item {
						direction: ltr;
					}
				}
			}
		}
		&[columns="1"] {
			.content-row {
				grid-template-columns: 1fr;
			}
		}
		&[columns="2"] {
			.content-row {
				grid-template-columns: 0.65fr 1fr;
			}
		}
		&[columns="3"] {
			.content-row {
				grid-template-columns: 0.65fr 1fr 1fr;
			}
		}
		&[columns="4"] {
			.content-row {
				grid-template-columns: 0.65fr 1fr 1fr 1fr;
			}
		}
		&[columns="5"] {
			.content-row {
				grid-template-columns: 0.65fr 1fr 1fr 1fr 1fr;
			}
		}
		&[columns="6"] {
			.content-row {
				grid-template-columns: 0.65fr 1fr 1fr 1fr 1fr 1fr;
			}
		}
	}
	&[columns="1"] {
		.content-row {
			grid-template-columns: 1fr;
		}
	}
	&[columns="2"] {
		.content-row {
			grid-template-columns: 1fr 1fr;
		}
	}
	&[columns="3"] {
		.content-row {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
	&[columns="4"] {
		.content-row {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
	&[columns="5"] {
		.content-row {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}
	}
	&[columns="6"] {
		.content-row {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		}
	}

	.inner-wrap {
		.content-row {
			display: grid;
			grid-auto-flow: dense;
			grid-gap: 10px;
			direction: ltr;
			margin: 0 0 10px;

			&:last-child {
				margin: 0;
			}

			.item {
				min-height: 200px;
				display: flex;
				justify-content: center;
				align-items: center;

				padding: 1rem;
				font-size: 14px;
				font-weight: bold;
				text-transform: uppercase;

				border-radius: 6px;
				background-size: cover;
				background-position: center center;

				position: relative;
				overflow: hidden;

				&:hover {
					&:before {
						opacity: 1;
						visibility: visible;
					}
				}

				&:before {
					@extend .ease-in-out-025s;
					@extend .secondary-bg-color;

					content: '';
					width: 100%;
					height: 100%;
				
					display: inline-block;
					cursor: pointer;
				
					opacity: 0;
					visibility: hidden;
					
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			.title,
			.title a {
				height: 100%;
				width: 100%;
				margin: 0;
	
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	@media( max-width: 992px ) {
		&[columns="3"],
		&[columns="4"],
		&[columns="5"],
		&[columns="6"] {
			&.rows-alternate__reverse .content-row,
			.content-row {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}
	}
	@media( max-width: 768px ) {
		&[columns="2"],
		&[columns="3"],
		&[columns="4"],
		&[columns="5"],
		&[columns="6"] {
			&.rows-alternate__reverse .content-row,
			.content-row {
				grid-template-columns: 1fr 1fr;
			}
		}
	}
	@media( max-width: 480px ) {
		&[columns="1"],
		&[columns="2"],
		&[columns="3"],
		&[columns="4"],
		&[columns="5"],
		&[columns="6"] {
			&.rows-alternate__reverse .content-row,
			.content-row {
				grid-template-columns: 1fr;
			}
		}
	}
}

// .popup-content.gridz-lightbox__wrapper-content {}