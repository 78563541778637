/**
 * Default Button Style
 */
.gp-button-defaults {
	@extend .light-color;
	@extend .primary-bg-color;
	@extend .material-shadow-hover-1;
	// @extend .ease-in-out-035s;

	min-height: 35px;
	padding: 7.5px 25px;
	border: none;
	outline: none;
	border-radius: 2px;
	// box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);

	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.8px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	cursor: pointer;
	user-select: none;

	white-space: nowrap;
	vertical-align: middle;
	display: inline-block;

	position: relative;

	&:hover,
	&:focus,
	&:active {
		@extend .light-color;
		background-color: lighten($primary, 8%);
		outline: none;
	}
	&:hover {
		text-decoration: none;
		// box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.225);
	}
}

/**
 * FAB - Floating Action Buttons
 */
.gp-button-fabs {
	&.btn-fab,
	&.btn-fab-mini {
		overflow: hidden;
		position: relative;
		margin: auto;
		padding: 0;
		line-height: normal;
		border-radius: 50%;
		box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3);
		&:hover {
			box-shadow: 0 4px 11px 0px rgba(0, 0, 0, 0.375);
		}
		i {
			display: inline-block;
			float: none;
			width: inherit;
			margin: 0;
			font-size: inherit;
			text-align: center;
			line-height: none;
			vertical-align: middle;
		}
	}

	&.btn-fab {
		width: 56px;
		height: 56px;
		font-size: 28px;
	}

	&.btn-fab-mini {
		width: 40px;
		height: 40px;
		font-size: 24px;
	}
}

/**
 * Button Colors
 */
.gp-button-colors {
	&.btn-blue {
		color: $light;
		background-color: $blue;
		&:hover {
			background-color: lighten($blue, 8%);
		}
	}

	&.btn-white {
		color: $dark;
		background-color: $light;
		&:hover {
			background-color: darken($light, 2%);
		}
	}

	&.btn-black {
		color: $light;
		background-color: $dark;
		&:hover {
			background-color: lighten($dark, 8%);
		}
	}

	&.btn-grey {
		color: $grey_03;
		background-color: $grey;
		&:hover {
			background-color: darken($grey, 4%);
		}
	}

	&.btn-orange {
		color: $light;
		background-color: $orange;
		&:hover {
			background-color: lighten($orange, 8%);
		}
	}

	&.btn-amber {
		color: $light;
		background-color: $amber;
		&:hover {
			background-color: lighten($amber, 8%);
		}
	}

	&.btn-green {
		color: $light;
		background-color: $green;
		&:hover {
			background-color: lighten($green, 8%);
		}
	}

	&.btn-teal {
		color: $light;
		background-color: $teal;
		&:hover {
			background-color: lighten($teal, 8%);
		}
	}

	&.btn-cyan {
		color: $light;
		background-color: $cyan;
		&:hover {
			background-color: lighten($cyan, 8%);
		}
	}

	&.btn-indigo {
		color: $light;
		background-color: $indigo;
		&:hover {
			background-color: lighten($indigo, 8%);
		}
	}

	&.btn-purple {
		color: $light;
		background-color: $deep_purple;
		&:hover {
			background-color: lighten($deep_purple, 8%);
		}
	}

	&.btn-pink {
		color: $light;
		background-color: $pink;
		&:hover {
			background-color: lighten($pink, 8%);
		}
	}

	&.btn-red {
		color: $light;
		background-color: $red;
		&:hover {
			background-color: lighten($red, 8%);
		}
	}

	&.btn-yellow {
		color: $light;
		background-color: $yellow;
		&:hover {
			background-color: darken($yellow, 13%);
		}
	}

	&.btn-lime {
		color: $light;
		background-color: $lime;
		&:hover {
			background-color: darken($lime, 8%);
		}
	}

	&.btn-brown {
		color: $light;
		background-color: $brown;
		&:hover {
			background-color: lighten($brown, 8%);
		}
	}
}

/**
 * Button Sizes
 */
.gp-button-sizes {
	&.btn-lg {
		height: 48px;
		line-height: 48px;
	}

	&.btn-sm {
		height: 30px;
		padding: 0 1rem;
		line-height: 30px;
		font-size: 12px;
	}

	&.btn-xs {
		height: 24px;
		padding: 0 0.4rem;
		line-height: 24px;
		font-size: 11px;
		font-weight: 300;
		letter-spacing: 0.2px;
	}
}

/**
 * Button Types
 */
.gp-button-types {
	&.disabled,
	&[disabled] {
		cursor: not-allowed !important;
		color: $grey !important;
		box-shadow: none !important;
	}

	&.disabled:not(.btn-flat),
	&[disabled]:not(.btn-flat) {
		background-color: $grey_03 !important;
		&:hover {
			background-color: $grey_03 !important;
		}
	}
	&.btn-round {
		border-radius: 6px;
	}
	&.btn-rounded {
		border-radius: 20px;
	}

	&.btn-flat {
		box-shadow: none !important;
		background-color: transparent !important;
		&:hover {
			background-color: $grey_03 !important;
			box-shadow: none !important;
		}

		&.disabled:hover,
		&[disabled]:hover {
			background-color: transparent !important;
		}
	}

	&.btn-link {
		color: $color__link !important;
		box-shadow: none !important;
		background-color: transparent !important;

		&:hover {
			text-decoration: underline !important;
			background-color: transparent !important;
			box-shadow: none !important;
		}

		&.disabled,
		&[disabled] {
			color: $grey !important;
			text-decoration: underline !important;
			background-color: transparent !important;
		}

		&.disabled:hover,
		&[disabled]:hover {
			background-color: transparent !important;
		}
	}

	i {
		float: left;
		width: auto;
		height: auto;
		margin-right: 10px;
		font-size: 1.3rem;
		line-height: inherit;

		&.right {
			float: right !important;
			margin: 0;
			margin-left: 10px;
		}
	}
}

/**
 * Material UI Ripple Effect
 */
.ripple-effect {
	display: inline-block;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	vertical-align: middle;
	user-select: none;
	z-index: 1;

	.ripple {
		display: block;
		position: absolute;
		border-radius: 100%;
		background-color: rgba($light, 0.725);
		transform: scale(0);
		pointer-events: none;
	}

	&.ripple-dark {
		.ripple {
			background-color: rgba($dark, 0.725);
		}
	}

	&.ripple-red {
		.ripple {
			background-color: rgba($red, 0.725);
		}
	}

	&.ripple-orange {
		.ripple {
			background-color: rgba($orange, 0.725);
		}
	}

	&.ripple-yellow {
		.ripple {
			background-color: rgba($yellow, 0.725);
		}
	}

	&.ripple-green {
		.ripple {
			background-color: rgba($green, 0.725);
		}
	}

	&.ripple-purple {
		.ripple {
			background-color: rgba($deep_purple, 0.725);
		}
	}

	&.ripple-teal {
		.ripple {
			background-color: rgba($teal, 0.725);
		}
	}

	&.ripple-pink {
		.ripple {
			background-color: rgba($pink, 0.725);
		}
	}

	.animated {
		animation: material-ripple 0.6s linear;
	}
}

/**
 * Theme Button
 */
.gp-btn,
a.gp-btn,
button.gp-btn {
	@extend .gp-button-defaults;
	@extend .gp-button-colors;
	@extend .gp-button-sizes;
	@extend .gp-button-fabs;
	@extend .gp-button-types;
}
button,
.gp-btn-ripple,
a.gp-btn-ripple,
button.gp-btn-ripple {
	@extend .gp-btn;
	@extend .ripple-effect;
}